<script setup>
import { useBanners } from "~/composables/useBanners";

const { getBannersByCategory, hasBannersByCategory } = useBanners();
const { sheets } = useApp();

const props = defineProps(["modelValue", "imageModel"]);
const emit = defineEmits(["update:modelValue", "goNext"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit("update:modelValue", val);
  },
});

const bannersColl = computed(() => {
  return getBannersByCategory(props.imageModel.banner_category);
});
const currentIndex = ref(0);
const currentImage = computed(() => bannersColl.value?.[currentIndex.value]);

function showNext() {
  currentIndex.value =
    currentIndex.value + 1 < bannersColl.value.length ? currentIndex.value + 1 : 0;
}

function showPrev() {
  currentIndex.value =
    currentIndex.value - 1 < 0 ? bannersColl.value.length - 1 : currentIndex.value - 1;
}

function shareImage() {
  sheets.shareSheet.text = toSharingImage(currentImage.value?.banner_slug);
  sheets.shareSheet.sheet = true;
}

function watchProps() {
  watch(
    () => props.imageModel,
    () => {
      currentIndex.value = bannersColl.value.findIndex(
        (el) => el._id == props.imageModel?._id
      );
    }
  );
}

function close() {
  dialog.value = false;
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <v-dialog v-model="dialog" fullscreen>
    <div class="dialog-container">
      <div class="dialog-header">
        <v-btn icon class="icon-size close" @click="close()"
          ><img src="/icons/close-v2.svg" alt=""
        /></v-btn>
      </div>

      <div class="dialog-content">
        <span
          class="dialog-content-wrapper"
          v-touch:swipe.left="() => showNext()"
          v-touch:swipe.right="() => showPrev()"
        >
          <div class="preview-image-wrapper">
            <div class="images">
              <div class="wraper-arrows">
                <div class="arrow">
                  <img @click="showPrev()" src="/icons/arrow-slider.svg" />
                </div>
                <div class="arrow right">
                  <img @click="showNext()" src="/icons/arrow-slider.svg" />
                </div>
              </div>
              <div class="image">
                <img
                  :src="resolveImage(currentImage?.banner_src)"
                  class="image-el"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <img src="/icons/scroll.svg" class="svg-icon icon-scroll" />
          <div class="dialog-footer">
            <div class="share-button my-2" @click="shareImage()">
              <span class="share-button-text"> {{ transl("Share") }}</span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.icon-size {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-top: 10px;
  margin-right: 10px;
  filter: brightness(0) saturate(100%);
}
.icon-size.close img {
  width: 16px;
  height: 16px;
}
.icon-scroll {
  margin: 17px auto 10px auto;
  width: 100%;
  height: 20px;
}
.icon-size img {
  width: 24px;
}
.wraper-arrows {
  width: 100%;
  max-width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
}
@media (max-width: 600px) {
  .wraper-arrows {
    display: none;
  }
}
@media (orientation: landscape) and (min-width: 600px) {
  .wraper-arrows {
    display: none;
  }
}
.preview-image-wrapper {
  position: relative;
  display: flex;
}
.dialog-content {
  display: flex;
  align-items: center;
  height: 90vh !important;
  justify-content: center;
}

.image {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.image-el {
  border-radius: 10px;
  border-radius: 10px;
  max-height: calc(100vh - 120px);
}
.arrow {
  width: 60px;
  height: 60px;
  transform: rotate(180deg);
  cursor: pointer;
}
.images {
  width: 70%;
  height: auto;
  max-width: 800px;
  max-height: calc(100vh - 120px);
  margin: auto;
  display: flex;
}
/* @media (max-width: 600px) {
  @supports not (aspect-ratio) {
    .images {
      padding-top: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
    }
    .images img {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: auto;
      max-width: 100%;
      height: auto;
    }
    .dialog-content-wrapper {
      width: 100%;
    }
  }
} */
@media (max-width: 600px) {
  .images {
    width: 90%;
  }
}
.arrow:hover {
  background-color: rgb(0 0 0 / 5%);
  border-radius: 5px;
}
.arrow.right {
  margin-left: auto;
  transform: rotate(0deg);
}
@media (max-width: 720px) {
  .arrow {
    width: 40px;
    height: 40px;
  }
}

.image-el:active {
  cursor: grabbing;
}
.share-button {
  max-width: 300px;
  background: var(--root-green);
  margin: 0 auto;
  color: var(--root-white);
  min-height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
}
.share-button:hover {
  opacity: 0.8;
}
.share-button-text {
  position: relative;
  padding-left: 35px;
  user-select: none;
}
.share-button .share-button-text::before {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(/icons/share.svg);
  position: absolute;
  left: 0;
  background-size: 20px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  background-repeat: no-repeat;
  background-position: center;
}
.dialog-footer {
  width: 100%;
  margin: auto;
}
@media (orientation: landscape) and (max-width: 767px) {
  .images {
    max-height: calc(100vh - 160px);
  }
  .dialog-content {
    height: 70vh !important;
  }
}
/* .dialog-container {
  display: grid;
  grid-template-rows: 70px minmax(35%, 65%) minmax(100px, 35%);
  height: 100%;
  background-color: var(--root-white);
  overflow: hidden;
} */

/* .dialog-content-wrapper {
  bottom: 0;
  cursor: default;
  display: block;
  height: 70vh;
  left: 0;
  margin: auto;
  right: 0;
  top: -2.5rem;
  width: 100%;
  position: relative;
} */
/* .dialog-content {
  padding: 0 80px;
  height: 100%;
}
.close-button {
  width: 24px;
  margin-left: auto;
} */
/* @media (min-width: 720px) {
  .close-button:hover {
    background-color: rgb(0 0 0 / 5%);
    border-radius: 5px;
  }
  .wraper-arrows {
    display: flex;
  }
} */

/* .preview-image-wrapper {

  height: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  width: 100%;
} */

/* .image-el {
  bottom: 0;
  box-shadow: 0 0 1.5rem #00000073;
  display: block;
  left: 0;
  margin: auto;
  max-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  cursor: grab;
  background: black;
  border-radius: 10px;
} */

/* .share-button:hover {
  background-color: #efefefa9;
}

.share-button:active {
  background-color: #cdcdcd;
} */
</style>
